
import { defineComponent, ref, onMounted, onActivated } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdown from "@/views/warehouse-management/stock-out/FilterDropdown.vue";
import FsLightbox from "fslightbox-vue/v3";
import { MenuComponent } from "@/assets/ts/components";
import { ApiStock } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { StockOutTable } from "@/core/directive/interface/stockOut";
import { previewOrderImages } from "@/core/directive/function/order";
import { ProductItemImage } from "@/core/directive/interface/order";
import store from "@/store";
import {
  commonChangeFilterAddDate,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "warehouse-management-stock-out",
  components: {
    MBDatatable,
    FilterDropdown,
    FsLightbox,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<StockOutTable>>([]);
    const checkedCompanys = ref([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);

    const options = ref({
      product_items: [],
      loadingProducts: true,
      toggler: false,
      sources: [] as any[],
      sourcesIndex: -1,
      sourcesSlide: 1,
    });

    const tableHeader = ref([
      {
        name: t("order.image"),
        key: "image",
        sortable: false,
      },
      {
        name: t("order.articleInformation"),
        key: "article_information",
        sortable: false,
      },
      {
        name: t("order.articleAttributes"),
        key: "article_attributes",
        sortable: false,
      },
      {
        name: t("stockOut.stockOutTime"),
        key: "stock_out_time",
        sortable: true,
      },
      {
        name: t("stockOut.warehouse"),
        key: "warehouse_id",
        sortable: false,
      },
      {
        name: t("stockOut.status"),
        key: "status",
        sortable: false,
      },
      {
        name: t("stockOut.stockOutType"),
        key: "stock_out_type",
        sortable: false,
      },
      {
        name: t("stockOut.targetQuantity"),
        key: "target_quantity",
        sortable: false,
        align: "right",
      },
      {
        name: t("stockOut.actualQuantity"),
        key: "actual_quantity",
        sortable: false,
        align: "right",
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getStockOutList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const getStockOutList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiStock.getStockOutList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            tableData.value.forEach((item) => {
              item.downloadDisabled = false;
            });
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getStockOutList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getStockOutList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getStockOutList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] !== "" && val[item] !== null) {
          if (
            item == "warehouse_id" ||
            item == "status" ||
            item == "stock_out_type" ||
            item == "exception_status"
          ) {
            if (val[item].length != 0)
              filterArr.push({
                field: item,
                value: val[item],
                condition: "in",
              });
          } else if (item == "stock_out_time_start") {
            filterArr.push({
              field: "stock_out_time",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "stock_out_time_end") {
            filterArr.push({
              field: "stock_out_time",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getStockOutList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getStockOutList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getStockOutList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const typeStatusClass = (val) => {
      const status = new Map([
        ["1", t("stockOut.stockOutTypeNormal")],
        ["2", t("stockOut.stockOutTypeAbnormal")],
      ]);
      if (val == 1) {
        return (
          '<span class="badge badge-success">' +
          status.get(String(val)) +
          "</span>"
        );
      } else {
        return (
          '<span class="badge badge-danger">' +
          status.get(String(val)) +
          "</span>"
        );
      }
    };

    const statusClass = (val) => {
      let str = "",
        statusClass = "";
      switch (val) {
        case "10":
          str = "预订出仓";
          statusClass = "badge-light-primary";
          break;
        case "20":
          str = "临时出仓";
          statusClass = "badge-light-info";
          break;
        case "30":
        case "40":
          str = val == 30 ? "列队进入" : "出仓进行中";
          statusClass = "badge-light-warning";
          break;
        case "50":
          str = "出仓完毕";
          statusClass = "badge-light-success";
          break;
        case "60":
          str = "取消";
          statusClass = "badge-light-dark";
          break;
        default:
          statusClass = "badge-light-primary";
      }
      return { str, statusClass };
    };

    const fileExport = (id, item) => {
      item.downloadDisabled = true;
      // ApiStock.print({
      //   id: id,
      //   download_type: "stream",
      // })
      //   .then((data) => {
      //     console.log(data.headers["content-disposition"]);
      //     item.downloadDisabled = false;
      //     const disposition = data.headers["content-disposition"];
      //     fileDownload(data.data, disposition.split("filename=")[1]);
      //   })
      //   .catch((error) => {
      //     item.downloadDisabled = false;
      //     console.log(error);
      //   });
    };

    const previewImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      options.value.sources = previewOrderImages(previewImages);
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      options,
      currentPage,
      total,
      pageSize,
      search,
      checkedCompanys,
      getStockOutList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      typeStatusClass,
      statusClass,
      fileExport,
      previewImages,
    };
  },
});
