
import { defineComponent, ref, onBeforeMount } from "vue";
import { ApiBase, ApiFulfillmentOrders } from "@/core/api";
import { useI18n } from "vue-i18n";

interface Filter {
  warehouse_id: Array<string>;
  status: Array<string>;
  stock_out_type: Array<string>;
  sku_identifier: string;
  exception_status: Array<string | number>;
  stock_out_time_start: null | string;
  stock_out_time_end: null | string;
}

export default defineComponent({
  name: "filter-warehouses-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      warehouse_id: [],
      status: [],
      stock_out_type: [],
      sku_identifier: "",
      exception_status: [],
      stock_out_time_start: null,
      stock_out_time_end: null,
    });

    const options = ref({
      warehouse: [],
      status: [],
      stock_out_type: [
        { label: t("stockOut.stockOutTypeNormal"), value: "1" },
        { label: t("stockOut.stockOutTypeAbnormal"), value: "2" },
      ],
      exception_status: [
        { label: t("common.yes"), value: 1 },
        { label: t("common.no"), value: 0 },
      ],
    });

    const getWarehouseData = async () => {
      const { data } = await ApiFulfillmentOrders.getWarehouseData({});
      if (data.code == 0) {
        options.value.warehouse = data.data;
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["stock_out_status"],
      });
      if (data.code == 0) {
        options.value.status = data.data.stock_out_status.items;
      }
    };

    const getFromInfo = async () => {
      Promise.all([getWarehouseData(), getTaggingData()])
        .then(() => {
          // console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onBeforeMount(() => {
      getFromInfo();
    });

    return {
      t,
      formData,
      formRef,
      options,
      submit,
      handleReset,
    };
  },
});
